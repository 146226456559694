import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { useGetAllCategoriesQuery } from "../../Data/services/categoryApi";
import {
  useAddProductMutation,
  useUpdateProductMutation,
} from "../../Data/services/productApi";
import { useGetAllSubCategoriesQuery } from "../../Data/services/subCategoryApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, CustomInput, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editProductModal.module.scss";

const EditProductModal = ({ handleEditModalClose, data, refetch }) => {
  const [rowId, setRowId] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      // name: data ? data.name : "",
      // mainCategory: data ? data.categoryId : "",
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const [filteredSubCategoryList, setFilteredSubCategoryList] = useState([]);

  // console.log(selectedCategory);
  // console.log(selectedCategoryId);
  // console.log(selectedSubCategory);
  // console.log(selectedSubCategoryId);

  // const categoryData = useSelector((state) => state.category.categories);

  const subCategoryData = useSelector(
    (state) => state.subCategories.subCategories
  );

  useGetAllCategoriesQuery({ refetchOnMountOrArgChange: true });

  useGetAllSubCategoriesQuery({ refetchOnMountOrArgChange: true });

  // const categoryList = categoryData.map((item) => ({
  //   label: item.name,
  //   value: item.id,
  // }));

  const subCategoryList = subCategoryData.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  // useEffect(() => {
  //   // console.log(selectedCategory);
  //   // console.log(selectedCategoryId);

  //   if (selectedCategoryId) {
  //     const filteredSubCategories = subCategoryData
  //       .filter(
  //         (subCategory) =>
  //           subCategory.categoryId === parseInt(selectedCategoryId)
  //       )
  //       .map((item) => ({
  //         label: item.name,
  //         value: item.id,
  //       }));

  //     // console.log(filteredSubCategories);

  //     setFilteredSubCategoryList(filteredSubCategories);
  //   }
  // }, [selectedCategoryId, subCategoryData, selectedCategory]);

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      console.log(data);
      setRowId(data.id);
      setValue("name", data.name);
      setValue("price", data.price);
      setValue("quantity", data.quantity);
      setValue("weight", data.weight);
      setValue("description", data.description);

      // categoryData.filter((category) => {
      //   if (data.categoryId === category.id) {
      //     setValue("categoryId", category.id);
      //     setSelectedCategoryId(category.id);
      //     setSelectedCategory(category.name);
      //   }
      // });

      // subCategoryData.filter((subCategory) => {
      //   if (data.subCategoryId === subCategory.id) {
      //     setValue("subCategoryId", subCategory.id);
      //     setSelectedSubCategoryId(subCategory.id);
      //     setSelectedSubCategory(subCategory.name);
      //   }
      // });

      // setValue("subCategoryId", data.subCategoryId);
    }
  }, [data, mounted]);

  // const handleCategoryChange = (value) => {
  //   console.log(value);
  //   setSelectedCategory(value);
  // };
  // const handleSubCategoryChange = (value) => {
  //   console.log(value);
  //   setSelectedSubCategory(value);
  // };

  // MARK: ADD PRODUCT
  const [addProduct, { isError, isLoading, isSuccess }] =
    useAddProductMutation();

  const handleAddProduct = async (data) => {
    console.log("handleAddProduct");
    console.log(data);

    // if (isSubmitted) return;

    try {
      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("price", data.price);
      formData.append("quantity", data.quantity);
      formData.append("weight", data.weight);
      setValue("description", data.description);

      // formData.append("categoryId", parseInt(data.categoryId));
      formData.append("subCategoryId", parseInt(data.subCategoryId));

      const images = [
        data.image1,
        data.image2,
        data.image3,
        data.image4,
        data.image5,
      ];

      images.forEach((image, index) => {
        if (image && image.length > 0) {
          formData.append(`productImages`, image[0]); // Assuming single file upload per image input
        }
      });

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await addProduct(formData);
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  // MARK: UPDATE PRODUCT
  const [
    updateProduct,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateProductMutation();

  const handleUpdateProduct = async (data) => {
    console.log("handleUpdateProduct");
    console.log(data);

    if (isSubmitted) return;
    try {
      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("price", data.price);
      formData.append("weight", data.weight);
      formData.append("quantity", data.quantity);
      formData.append("description", data.description);

      // formData.append("categoryId", parseInt(data.categoryId));
      // formData.append("subCategoryId", parseInt(data.subCategoryId));

      const images = [
        data.image1,
        data.image2,
        data.image3,
        data.image4,
        data.image5,
      ];

      images.forEach((image, index) => {
        if (image && image.length > 0) {
          formData.append(`productImages`, image[0]);
        }
      });

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }
      const id = rowId;
      const response = await updateProduct({ formData, id });
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? "Edit Product" : "Add Product"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <Form
            onSubmit={handleSubmit(
              data ? handleUpdateProduct : handleAddProduct
            )}
            className="w-100"
          >
            <Row className="w-100">
              <Col lg={6}>
                <Row>
                  <Col md={12}>
                    <p className="m-0 gray-text">Product Image 1</p>
                    <Controller
                      control={control}
                      name="image1"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CustomInput
                          classes="mt-2 mb-3"
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.files);
                            onChange(e.target.files);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="m-0 gray-text">Product Image 2</p>
                    <Controller
                      control={control}
                      name="image2"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CustomInput
                          classes="mt-2 mb-3 "
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.files);
                            onChange(e.target.files);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="m-0 gray-text">Product Image 3</p>
                    <Controller
                      control={control}
                      name="image3"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CustomInput
                          classes="mt-2 mb-3"
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.files);
                            onChange(e.target.files);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="m-0 gray-text">Product Image 4</p>
                    <Controller
                      control={control}
                      name="image4"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CustomInput
                          classes="mt-2 mb-3"
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.files);
                            onChange(e.target.files);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <p className="m-0 gray-text">Product Image 5</p>
                    <Controller
                      control={control}
                      name="image5"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <CustomInput
                          classes="mt-2 mb-3"
                          type="file"
                          onChange={(e) => {
                            console.log(e.target.files);
                            onChange(e.target.files);
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>

              <Col lg={6}>
                <div className="w-100">
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Product Name
                        {errors?.name?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.name?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="name"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            placeholder="Enter product name"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Product Price
                        {errors?.price?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.price?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="price"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            placeholder="Enter product price"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Product Quantity
                        {errors?.quantity?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.quantity?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="quantity"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            placeholder="Enter product quantity"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Product Weight (in pounds)
                        {errors?.weight?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.weight?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="weight"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            placeholder="Enter product weight in pounds"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Product Description{" "}
                        {errors?.description?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.description?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="description"
                        rules={{
                          required: "required.",
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="text"
                            rows={5}
                            as="textarea"
                            name="description"
                            placeholder="Enter description"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {
                    // <Row>
                    //   <Col md={12}>
                    //     <p className="m-0 gray-text">
                    //       Category
                    //       {errors?.categoryId?.message ? (
                    //         <span className="ms-2 text-danger text-center">
                    //           {errors?.categoryId?.message}
                    //         </span>
                    //       ) : (
                    //         ""
                    //       )}
                    //     </p>
                    //     <Controller
                    //       control={control}
                    //       name="categoryId"
                    //       rules={{
                    //         required: "required.",
                    //       }}
                    //       render={({
                    //         field: { onChange, onBlur, value, ref },
                    //       }) => (
                    //         <SelectFieldComponent
                    //           firstOption={
                    //             _.isEmpty(selectedCategory)
                    //               ? "Select Category"
                    //               : selectedCategory
                    //           }
                    //           optionsList={categoryList}
                    //           value={value}
                    //           onSelectChange={(value) => {
                    //             // console.log(value);
                    //             onChange(value);
                    //             // setSelectedCategory(value);
                    //             setSelectedCategoryId(value);
                    //           }}
                    //         />
                    //       )}
                    //     />
                    //   </Col>
                    // </Row>
                  }
                  {data ? (
                    ""
                  ) : (
                    <Row>
                      <Col md={12}>
                        <p className="m-0 gray-text">
                          Sub Category
                          {errors?.subCategoryId?.message ? (
                            <span className="ms-2 text-danger text-center">
                              {errors?.subCategoryId?.message}
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                        <Controller
                          control={control}
                          name="subCategoryId"
                          // defaultValue={""}
                          rules={{
                            required: "required.",
                          }}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <SelectFieldComponent
                              firstOption={
                                _.isEmpty(selectedSubCategory)
                                  ? "Select Sub Category"
                                  : selectedSubCategory
                              }
                              optionsList={subCategoryList}
                              value={value}
                              onSelectChange={(value) => {
                                // console.log(value);
                                onChange(value);
                                // setSelectedSubCategory(value);
                                setSelectedSubCategoryId(value);
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </Col>
            </Row>

            <div className="w-100">
              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={
                      isLoading || updateLoading ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    // onClick={handleSubmit}
                    type="submit"
                    disabled={isLoading || updateLoading}
                  ></Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditProductModal;
