import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row, Table } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { useUpdateOrderMutation } from "../../Data/services/orderApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, Description, SubTitle } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/viewOrderModal.module.scss";

const ViewOrderModal = ({ handleEditModalClose, data, refetch }) => {
  const [rowId, setRowId] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      // name: data ? data.name : "",
      // mainCategory: data ? data.categoryId : "",
    },
  });
  // const [selectedRole, setSelectedRole] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");

  const paymentStatusList = [
    { label: "Paid", value: "paid" },
    { label: "Unpaid", value: "unpaid" },
  ];
  const orderStatusList = [
    { label: "Pending", value: "pending" },
    { label: "Delivered", value: "delivered" },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    // console.log(data);
    // console.log(data.username)
    // console.log(data.email)
    if (data) {
      setRowId(data.id);
      // console.log(data.status);
      // console.log(data.isPaid);
      if (data.status === "pending") {
        setSelectedOrderStatus("pending");
      } else {
        setSelectedOrderStatus("delivered");
      }
      if (data.isPaid) {
        setSelectedPaymentStatus("paid");
      } else {
        setSelectedPaymentStatus("unpaid");
      }
    }
  }, [data, mounted]);

  const handlePaymentStatusChange = (value) => {
    console.log(value);
    setSelectedPaymentStatus(value);
  };
  const handleOrderStatusChange = (value) => {
    console.log(value);
    setSelectedOrderStatus(value);
  };

  // MARK: UPDATE PRODUCT
  const [
    updateOrder,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateOrderMutation();

  const handleUpdateOrder = async (data) => {
    // console.log(data);
    // console.log(selectedPaymentStatus);
    // console.log(selectedOrderStatus);

    if (isSubmitted) return;
    try {
      setIsSubmitted(true);

      const formData = {
        status: selectedOrderStatus,
      };

      const id = rowId;
      const response = await updateOrder({ formData, id });
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? `View Order #${data.id}` : ""}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Row>
              <Col lg={12}>
                <Table>
                  <thead>
                    <th>Product Image</th>
                    <th>Name</th>
                    <th>Quantity</th>
                    <th>Price</th>
                  </thead>
                  {data.orderProducts.map((product, index) => {
                    // console.log(product);
                    return (
                      <tr>
                        <td>
                          <img
                            src={product.product.product_images[0].image}
                            alt="Product title"
                            width={100}
                            height={100}
                            style={{ objectFit: "cover" }}
                          />
                        </td>
                        <td>{product.product.name}</td>
                        <td>{product.quantity}</td>
                        <td>{product.product.price}</td>
                      </tr>
                    );
                  })}
                </Table>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="d-flex justify-content-around">
                  <div className="d-flex flex-column align-items-center">
                    <SubTitle text="Total Items" classes="black-text fs-6" />
                    <Description
                      text={data.totalItems}
                      classes="black-text fs-5 fw-bold"
                    />
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <SubTitle text="Total Payment" classes="black-text fs-6" />
                    <Description
                      text={data.totalPayment}
                      classes="black-text fs-5 fw-bold"
                    />
                  </div>
                </div>
                <br></br>
                <div className="primary-border-btn rounded-3 p-1 ">
                  Customer Details:
                  {data.deliveryAddress !== null ? (
                    <>
                      {" "}
                      <p className="m-0">
                        Customer Name:{" "}
                        <b>{data.deliveryAddress.fullName ?? ""}</b>
                      </p>
                      <p className="">
                        Phone#: <i>{data.deliveryAddress.phoneNumber ?? ""}</i>
                      </p>
                      Address Details:
                      <p className="m-0">
                        Label: <i>{data.deliveryAddress.label ?? ""}</i>
                      </p>
                      <p className="m-0">
                        City: <b>{data.deliveryAddress.city ?? ""}</b>
                      </p>
                      <p className=" m-0">
                        Area code: <i>{data.deliveryAddress.areaCode ?? ""}</i>
                      </p>
                      <p className="">
                        Address:{" "}
                        <b>{data.deliveryAddress.completeAddress ?? ""}</b>
                      </p>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </Col>

              <Col lg={6}>
                <Form
                  onSubmit={handleSubmit(handleUpdateOrder)}
                  className="w-100"
                >
                  {
                    //   <Row>
                    //   <Col md={12}>
                    //     <p className="m-0">Payment Status</p>
                    //     <Controller
                    //       control={control}
                    //       name="status"
                    //       // defaultValue={""}
                    //       // rules={{
                    //       //   required: "Sub Category Name is required.",
                    //       // }}
                    //       render={({
                    //         field: { onChange, onBlur, value, ref },
                    //       }) => (
                    //         <SelectFieldComponent
                    //           firstOption={
                    //             _.isEmpty(selectedPaymentStatus)
                    //               ? "Payment Status"
                    //               : selectedPaymentStatus
                    //           }
                    //           optionsList={paymentStatusList}
                    //           onSelectChange={handlePaymentStatusChange}
                    //           value={value}
                    //           // onSelectChange={onChange}
                    //         />
                    //       )}
                    //     />
                    //   </Col>
                    // </Row>
                  }
                  <Row>
                    <Col md={12}>
                      <p className="m-0">Order Status</p>
                      <Controller
                        control={control}
                        name="isPaid"
                        // defaultValue={""}
                        // rules={{
                        //   required: "Sub Category Name is required.",
                        // }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <SelectFieldComponent
                            firstOption={
                              _.isEmpty(selectedOrderStatus)
                                ? "Order Status"
                                : selectedOrderStatus
                            }
                            optionsList={orderStatusList}
                            onSelectChange={handleOrderStatusChange}
                            value={value}
                            // onSelectChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Button
                        classes={`${cssStyles.cardBtn} mt-4 `}
                        text={
                          updateLoading ? (
                            <BeatLoader size={10} />
                          ) : data ? (
                            "Update"
                          ) : (
                            "Save"
                          )
                        }
                        // onClick={handleSubmit}
                        type="submit"
                        disabled={updateLoading}
                      ></Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default ViewOrderModal;
