import React from "react";

import { IoIosArrowBack } from "react-icons/io";
import { useGetAllStaticContentQuery } from "../../Data/services/settingsApi";
import AboutUs from "./AboutUs";
import CancellationPolicy from "./CancellationPolicy";
import ChangePassword from "./ChangePassword";
import ContactUs from "./ContactUs";
import { ROUTES } from "./contants";
import CookiePolicy from "./CookiePolicy";
import DeliveryAddress from "./DeliveryAddress";
import Disclaimer from "./Disclaimer";
import EditProfile from "./EditProfile";
import LicenseAgreement from "./LicenseAgreement";
import PrivacyPolicy from "./PrivacyPolicy";
import RefundPolicy from "./RefundPolicy";
import styles from "./styles/Details.module.scss";
import TermsAndConditions from "./TermsAndConditions";

export default function Details({ isMobile = false, route = "", setRoute }) {
  const handleBackButtonClck = () => {
    setRoute("");
  };

  const { data, refetch } = useGetAllStaticContentQuery({
    refetchOnMountOrArgChange: true,
  });

  return (
    <div className={styles.Details}>
      {!isMobile ? (
        ""
      ) : (
        <span className={styles.backButton} onClick={handleBackButtonClck}>
          <IoIosArrowBack />
        </span>
      )}
      {route === ROUTES.EDIT_PROFILE ? (
        <EditProfile />
      ) : route === ROUTES.CHANGE_PASSWORD ? (
        <ChangePassword />
      ) : route === ROUTES.DISCLAIMER ? (
        <Disclaimer refetch={refetch} />
      ) : route === ROUTES.COOKIE_POLICY ? (
        <CookiePolicy refetch={refetch} />
      ) : route === ROUTES.PRIVACY_POLICY ? (
        <PrivacyPolicy refetch={refetch} />
      ) : route === ROUTES.TERMS_CONDITIONS ? (
        <TermsAndConditions refetch={refetch} />
      ) : route === ROUTES.LICENSE_AGREEMENT ? (
        <LicenseAgreement refetch={refetch} />
      ) : route === ROUTES.ABOUT_US ? (
        <AboutUs refetch={refetch} />
      ) : route === ROUTES.REFUND_POLICY ? (
        <RefundPolicy refetch={refetch} />
      ) : route === ROUTES.CANCELLATION_POLICY ? (
        <CancellationPolicy refetch={refetch} />
      ) : route === ROUTES.CONTACT_US ? (
        <ContactUs />
      ) : route === ROUTES.DELIVERY_ADDRESS ? (
        <DeliveryAddress />
      ) : (
        ""
      )}
    </div>
  );
}
