import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Button, Input } from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { ACCOUNT_ENUM } from "./Route";

import { useNavigate } from "react-router-dom";

import { Controller, useForm } from "react-hook-form";
// import Logo from "../../Components/CommonComponents/Logo";
// import { IMAGES } from "../../Constant/ImageConstant";
// import {
//   INPUT_PATTERNS,
//   INPUT_TYPES,
// } from "../../Constant/InputTypesAndPatternsConstant";
// import { RegisterPayload } from "../../Constant/PayloadConstant/RegisterPayloadConstant";
// import { STRING } from "../../Constant/StringConstant";
// import { requestSportsTypeDTOs } from "../../Data/DTO/registerDTO";
// import { useGetSportsLookupQuery } from "../../Data/services/lookupApi";
// import { useRegisterUserMutation } from "../../Data/services/userAuthApi";
import { useRegisterUserMutation } from "../../Data/services/userAuthApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
// import LoginLink from "./Components/LoginLink";
// import RegisterYourself from "./Components/RegisterYourself";

export default function Register({ setPage, setEmail, setAfterRegister }) {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loading = [
    { label: "Loading...", value: "Loading...", disabled: true },
  ];

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm({});

  // Watch the password field value to compare with confirm password
  const password = watch("password");

  const handleLoginClick = () => setPage(ACCOUNT_ENUM.LOGIN);
  const handleSendToOTPVerification = () => setPage(ACCOUNT_ENUM.OTP);

  const [registerUser, { isError, isLoading, isSuccess }] =
    useRegisterUserMutation();

  const handleRegister = async (data) => {
    console.log("handle Register");
    data.role = "vendor";
    console.log(data);

    try {
      const response = await registerUser(data);
      console.log(response);

      if (response.error && response.error.status === 400) {
        const message = await response.error.data.message;
        Toast(`${message}`, "error");
        console.log(message);
      }

      if (!response.error && response.data.success === true) {
        // console.log(response.data.data)
        // Toast('You are registered successfully, Now Login with your credentials', "success");
        SuccessToast("Successfully registered.");

        // handleLoginClick();
        setEmail(data.email);
        setAfterRegister(true);
        handleSendToOTPVerification();
      }
    } catch (error) {
      console.log(error);
      if (error?.data && error?.data?.message) {
        Toast(error?.data?.message, "error");
      } else {
        Toast("An error occurred during registration", "error");
      }
    }
  };

  return (
    <AccountLayout
    // leftSideImage={IMAGES.LOGIN}
    >
      {errors
        ? errors?.name?.message
          ? Toast(errors?.name?.message, "error")
          : errors?.email?.message
          ? Toast(errors?.email?.message, "error")
          : errors?.password?.message
          ? Toast(errors?.password?.message, "error")
          : errors?.confirm_password?.message
          ? Toast(errors?.confirm_password?.message, "error")
          : errors?.sports_type?.message
          ? Toast(errors?.sports_type?.message, "error")
          : errors?.agree?.message
          ? Toast(errors?.agree?.message, "error")
          : null
        : ""}

      <Form onSubmit={handleSubmit(handleRegister)} className="w-100 ">
        <Row>
          <Col lg={6}>
            <Controller
              control={control}
              name="firstName"
              rules={{
                required: "first name required.",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <InputGroup>
                  <Input
                    placeholder={"First name"}
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </InputGroup>
              )}
            />
          </Col>
          <Col lg={6}>
            <Controller
              control={control}
              name="lastName"
              rules={{
                required: "last name required.",
              }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <InputGroup>
                  <Input
                    placeholder={"Last name"}
                    type="text"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                </InputGroup>
              )}
            />
          </Col>
        </Row>

        <Controller
          control={control}
          name="email"
          rules={{
            required: "Email required",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                placeholder="Email"
                type="email"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="password"
          rules={{
            required: "Password required.",
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder="Password"
                type="password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />

        <Controller
          control={control}
          name="confirmPassword"
          rules={{
            required: "Confirm password required.",
            // minLength: {
            //   value: 6,
            //   message: STRING.ConfirmPasswordSixCharacterRequired,
            // },
            // pattern: {
            //   value: INPUT_PATTERNS.PASSWORD_PATTERNS,
            //   message: STRING.ConfirmPasswordPatternRequired,
            // },
            // validate: (value) =>
            //   value === password || STRING.ConfirmPasswordMustMatch,
          }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup>
              <Input
                classes="mt-2 mb-2"
                placeholder="Password again"
                type="password"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
              />
            </InputGroup>
          )}
        />
        {
          // <Controller
          //   control={control}
          //   name="agree"
          //   rules={{
          //     required: "Must be agree with Condition and Privacy Policy.",
          //   }}
          //   render={({ field: { onChange, onBlur, value, ref } }) => (
          //     <Checkbox
          //       label={STRING.ContinueWithSocialLogin}
          //       secondLabel={STRING.TermsAndCondition}
          //       classes="mt-2 mb-2"
          //       checked={value}
          //       onChange={onChange}
          //       onBlur={onBlur}
          //     />
          //   )}
          // />
        }

        <Button
          classes="mt-4 mb-2 puff-in-hor"
          text={isLoading ? "Sending" : "Register"}
          type="submit"
          disabled={isLoading}
        />
      </Form>
      <div className="d-flex flex-row justify-content-between w-100 wrap">
        <span
          onClick={handleLoginClick}
          className=" cursor-pointer primary-text"
        >
          Already have an account?
        </span>
      </div>
    </AccountLayout>
  );
}
