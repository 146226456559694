import React, { useMemo } from "react";

import { RiLockPasswordFill } from "react-icons/ri";
import styles from "./styles/Menu.module.scss";

import { FaAddressCard } from "react-icons/fa6";
import { Description, SubTitle } from "../../Components/CustomComponents";
import { ROUTES } from "./contants";

export default function Menu({ route, setRoute }) {
  const OPTIONS = useMemo(() => {
    return [
      // {
      //     label: 'Edit Profile',
      //     route: ROUTES.EDIT_PROFILE,
      //     icon: <EditIcon />
      // },
      {
        label: "Change Password",
        route: ROUTES.CHANGE_PASSWORD,
        icon: <RiLockPasswordFill size={20} />,
      },
      {
        label: "Delivery Address",
        route: ROUTES.DELIVERY_ADDRESS,
        icon: <FaAddressCard size={20} />,
      },
      // {
      //   label: "Disclaimer",
      //   route: ROUTES.DISCLAIMER,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "Cookie Policy",
      //   route: ROUTES.COOKIE_POLICY,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "Privacy Policy",
      //   route: ROUTES.PRIVACY_POLICY,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "Terms & Conditions",
      //   route: ROUTES.TERMS_CONDITIONS,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "License Agreement",
      //   route: ROUTES.LICENSE_AGREEMENT,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "About Us",
      //   route: ROUTES.ABOUT_US,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "Refund Policy",
      //   route: ROUTES.REFUND_POLICY,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "Cancellation Policy",
      //   route: ROUTES.CANCELLATION_POLICY,
      //   icon: <SiGoogledocs size={20} />,
      // },
      // {
      //   label: "Contact Us",
      //   route: ROUTES.CONTACT_US,
      //   icon: <MdMarkEmailRead size={20} />,
      // },
    ];
  }, []);

  const handleMenuItemClick = (route) => {
    setRoute(route);
  };

  return (
    <div className={styles.Menu}>
      <SubTitle text="Setting" classes="black-text" />
      <Description
        text="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        classes="mt-1 mb-4 fs-8 black-text"
      />
      {OPTIONS.map((item, index) => {
        return (
          <div
            className={`${styles.option} my-3 ${
              route === item.route ? styles.active : ""
            }`}
            key={index}
            onClick={() => handleMenuItemClick(item.route)}
          >
            <span className={styles.icon}>{item?.icon}</span>
            <Description text={item.label} classes="fs-7 black-text" />
          </div>
        );
      })}
    </div>
  );
}
